import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { ProductDocumentInterface } from "src/app/share/interface/motor.interface";

@Component({
  selector: "app-insurer-card-v2",
  templateUrl: "./insurer-card-v2.component.html",
  styleUrls: ["./insurer-card-v2.component.scss"],
})
export class InsurerCardV2Component implements OnInit {
  @Input() first: boolean = false;
  @Input() item: IInsurerData;
  @Output() onSubmit = new EventEmitter();

  benefits: IBenefit[] = [];
  showMoreBenefits: boolean = true;
  documents: ProductDocumentInterface[] = [];
  totalBenefits: number;

  constructor(
    private motorDataDataService: MotorDataDataService,
    private translate: TranslateService,
  ) {
    this.translate.onLangChange.subscribe((_event: LangChangeEvent) =>
      this.getProductDocument(),
    );
  }

  ngOnInit(): void {
    this.getProductDocument();
    this.displayBenefits();
  }

  hasTranslation(key: string): boolean {
    const translation = this.translate.instant(key);
    if (translation.length) return true;
    return false;
  }

  toggleMoreBenefits() {
    if (this.showMoreBenefits) {
      this.showMoreBenefits = false;
    } else {
      this.showMoreBenefits = true;
    }

    this.displayBenefits();
  }

  private displayBenefits() {
    let tempBenefits: { type: string; description: string }[] =
      INSURER_BENEFITS_LIST[this.item.code].filter((x) =>
        this.hasTranslation(x.description),
      );

    //totalBenefits is used to show user the total number of benefits there are
    // before user clicks on "See all benefits"
    this.totalBenefits = tempBenefits.length;

    if (this.showMoreBenefits) {
      this.benefits = tempBenefits;
    } else {
      this.benefits = tempBenefits.slice(0, 4);
    }
  }

  //Note: Ideally this API should not be called in a presentational component.
  //To be refactored in the parent component and passed in under a modified version of "item"
  private getProductDocument() {
    if (!this.item) return;
    this.motorDataDataService
      .getProductDocumentById(
        this.item.productId,
        this.translate.currentLang,
        this.item.typeOfSum,
      )
      .subscribe({
        next: (x) => {
          this.documents = x;
        },
      });
  }

  //NOTE: To update accordingly. What's the number that we should compare with?
  get eppEligibility() {
    let eppEligibilityObject: { isEligible: boolean; months: number } = null;
    const eppAmount = this.totalBasePremiumAfterRebate;

    if (eppAmount >= 500 && eppAmount < 999) {
      eppEligibilityObject = { isEligible: true, months: 6 };
    }

    if (eppAmount > 999) {
      eppEligibilityObject = { isEligible: true, months: 12 };
    }

    return eppEligibilityObject;
  }

  get totalBasePremiumAfterRebate() {
    return this.item?.totalBasePremium - this.item?.rebate || 0;
  }

  get rebateAmount() {
    return this.item?.rebate;
  }

  get totalPrice() {
    return this.totalCharges + this.item?.serviceTaxRate * this.totalCharges;
  }

  get totalBasePremium() {
    return this.item?.totalBasePremium;
  }

  get totalCharges() {
    return (
      this.totalBasePremium +
      (this.item?.taxRate * this.totalBasePremium + this.item?.stampDutyPrice)
    );
  }

  handleBuyNow() {
    this.onSubmit.emit(this.item.id);
  }
}

export interface IInsurerData {
  image: string;
  typeOfSum: string;
  sumInsured: number;
  code: string;
  total: number;
  id: string;
  basePremium: number;
  totalBasePremium: number;
  serviceTaxRate: number;
  taxRate: number;
  stampDutyPrice: number;
  defaultExcessDiscount?: number;
  rebate: number;
  productId: string;
}

export interface IBenefit {
  type: string;
  description: string;
}

//NOTE: This list does not need to be updated if the copywriting of the benefit changes
//UNLESS new type of benefit is included or removed completely.
//Any changes in copywriting should only be updated at the i18n file.
export const INSURER_BENEFITS_LIST = {
  PolicyStreet: [
    { type: "driver", description: "benefits.PolicyStreet.additionalDriver" },
    { type: "towing", description: "benefits.PolicyStreet.emergencyTowing" },
    { type: "free", description: "benefits.PolicyStreet.batteryService" },
    { type: "free", description: "benefits.PolicyStreet.tankService" },
    { type: "free", description: "benefits.PolicyStreet.tyreService" },
    { type: "free", description: "benefits.PolicyStreet.roadsideAssistance" },
    { type: "free", description: "benefits.PolicyStreet.claimAssistance" },
    { type: "free", description: "benefits.PolicyStreet.lockSmith" },
    { type: "", description: "benefits.PolicyStreet.eHailing" },
    { type: "", description: "benefits.PolicyStreet.instantApproval" },
    { type: "", description: "benefits.PolicyStreet.easyAndFastClaims" },
    { type: "", description: "benefits.PolicyStreet.coverForFEL" },
    { type: "", description: "benefits.PolicyStreet.coverForTheft" },
    { type: "", description: "benefits.PolicyStreet.oldVehicles" },
    { type: "", description: "benefits.PolicyStreet.cashback" },
    { type: "", description: "benefits.PolicyStreet.others" },
  ],
  AXA: [
    { type: "driver", description: "benefits.AXA.additionalDriver" },
    { type: "towing", description: "benefits.AXA.emergencyTowing" },
    { type: "free", description: "benefits.AXA.batteryService" },
    { type: "free", description: "benefits.AXA.tankService" },
    { type: "free", description: "benefits.AXA.tyreService" },
    { type: "free", description: "benefits.AXA.roadsideAssistance" },
    { type: "free", description: "benefits.AXA.claimAssistance" },
    { type: "free", description: "benefits.AXA.lockSmith" },
    { type: "", description: "benefits.AXA.eHailing" },
    { type: "", description: "benefits.AXA.instantApproval" },
    { type: "", description: "benefits.AXA.easyAndFastClaims" },
    { type: "", description: "benefits.AXA.coverForFEL" },
    { type: "", description: "benefits.AXA.coverForTheft" },
    { type: "", description: "benefits.AXA.oldVehicles" },
    { type: "", description: "benefits.AXA.cashback" },
    { type: "", description: "benefits.AXA.others" },
  ],
  Generali: [
    { type: "driver", description: "benefits.Generali.additionalDriver" },
    { type: "towing", description: "benefits.Generali.emergencyTowing" },
    { type: "free", description: "benefits.Generali.batteryService" },
    { type: "free", description: "benefits.Generali.tankService" },
    { type: "free", description: "benefits.Generali.tyreService" },
    { type: "free", description: "benefits.Generali.roadsideAssistance" },
    { type: "free", description: "benefits.Generali.claimAssistance" },
    { type: "free", description: "benefits.Generali.lockSmith" },
    { type: "", description: "benefits.Generali.eHailing" },
    { type: "", description: "benefits.Generali.instantApproval" },
    { type: "", description: "benefits.Generali.easyAndFastClaims" },
    { type: "", description: "benefits.Generali.coverForFEL" },
    { type: "", description: "benefits.Generali.coverForTheft" },
    { type: "", description: "benefits.Generali.oldVehicles" },
    { type: "", description: "benefits.Generali.cashback" },
    { type: "", description: "benefits.Generali.others" },
  ],
  Allianz: [
    { type: "driver", description: "benefits.Allianz.additionalDriver" },
    { type: "towing", description: "benefits.Allianz.emergencyTowing" },
    { type: "free", description: "benefits.Allianz.batteryService" },
    { type: "free", description: "benefits.Allianz.tankService" },
    { type: "free", description: "benefits.Allianz.tyreService" },
    { type: "free", description: "benefits.Allianz.roadsideAssistance" },
    { type: "free", description: "benefits.Allianz.claimAssistance" },
    { type: "free", description: "benefits.Allianz.lockSmith" },
    { type: "", description: "benefits.Allianz.eHailing" },
    { type: "", description: "benefits.Allianz.instantApproval" },
    { type: "", description: "benefits.Allianz.easyAndFastClaims" },
    { type: "", description: "benefits.Allianz.coverForFEL" },
    { type: "", description: "benefits.Allianz.coverForTheft" },
    { type: "", description: "benefits.Allianz.oldVehicles" },
    { type: "", description: "benefits.Allianz.cashback" },
    { type: "", description: "benefits.Allianz.others" },
  ],
  Zurich: [
    { type: "driver", description: "benefits.Zurich.additionalDriver" },
    { type: "towing", description: "benefits.Zurich.emergencyTowing" },
    { type: "free", description: "benefits.Zurich.batteryService" },
    { type: "free", description: "benefits.Zurich.tankService" },
    { type: "free", description: "benefits.Zurich.tyreService" },
    { type: "free", description: "benefits.Zurich.roadsideAssistance" },
    { type: "free", description: "benefits.Zurich.claimAssistance" },
    { type: "free", description: "benefits.Zurich.lockSmith" },
    { type: "", description: "benefits.Zurich.eHailing" },
    { type: "", description: "benefits.Zurich.instantApproval" },
    { type: "", description: "benefits.Zurich.easyAndFastClaims" },
    { type: "", description: "benefits.Zurich.coverForFEL" },
    { type: "", description: "benefits.Zurich.coverForTheft" },
    { type: "", description: "benefits.Zurich.oldVehicles" },
    { type: "", description: "benefits.Zurich.cashback" },
    { type: "", description: "benefits.Zurich.others" },
  ],
  MSIG: [
    { type: "driver", description: "benefits.MSIG.additionalDriver" },
    { type: "towing", description: "benefits.MSIG.emergencyTowing" },
    { type: "free", description: "benefits.MSIG.batteryService" },
    { type: "free", description: "benefits.MSIG.tankService" },
    { type: "free", description: "benefits.MSIG.tyreService" },
    { type: "free", description: "benefits.MSIG.roadsideAssistance" },
    { type: "free", description: "benefits.MSIG.claimAssistance" },
    { type: "free", description: "benefits.MSIG.lockSmith" },
    { type: "", description: "benefits.MSIG.eHailing" },
    { type: "", description: "benefits.MSIG.instantApproval" },
    { type: "", description: "benefits.MSIG.easyAndFastClaims" },
    { type: "", description: "benefits.MSIG.coverForFEL" },
    { type: "", description: "benefits.MSIG.coverForTheft" },
    { type: "", description: "benefits.MSIG.oldVehicles" },
    { type: "", description: "benefits.MSIG.cashback" },
    { type: "", description: "benefits.MSIG.others" },
  ],
  Kurnia: [
    { type: "driver", description: "benefits.Kurnia.additionalDriver" },
    { type: "towing", description: "benefits.Kurnia.emergencyTowing" },
    { type: "free", description: "benefits.Kurnia.batteryService" },
    { type: "free", description: "benefits.Kurnia.tankService" },
    { type: "free", description: "benefits.Kurnia.tyreService" },
    { type: "free", description: "benefits.Kurnia.roadsideAssistance" },
    { type: "free", description: "benefits.Kurnia.claimAssistance" },
    { type: "free", description: "benefits.Kurnia.lockSmith" },
    { type: "", description: "benefits.Kurnia.eHailing" },
    { type: "", description: "benefits.Kurnia.instantApproval" },
    { type: "", description: "benefits.Kurnia.easyAndFastClaims" },
    { type: "", description: "benefits.Kurnia.coverForFEL" },
    { type: "", description: "benefits.Kurnia.coverForTheft" },
    { type: "", description: "benefits.Kurnia.oldVehicles" },
    { type: "", description: "benefits.Kurnia.cashback" },
    { type: "", description: "benefits.Kurnia.others" },
  ],
  RHB: [
    { type: "driver", description: "benefits.RHB.additionalDriver" },
    { type: "towing", description: "benefits.RHB.emergencyTowing" },
    { type: "free", description: "benefits.RHB.batteryService" },
    { type: "free", description: "benefits.RHB.tankService" },
    { type: "free", description: "benefits.RHB.tyreService" },
    { type: "free", description: "benefits.RHB.roadsideAssistance" },
    { type: "free", description: "benefits.RHB.claimAssistance" },
    { type: "free", description: "benefits.RHB.lockSmith" },
    { type: "", description: "benefits.RHB.eHailing" },
    { type: "", description: "benefits.RHB.instantApproval" },
    { type: "", description: "benefits.RHB.easyAndFastClaims" },
    { type: "", description: "benefits.RHB.coverForFEL" },
    { type: "", description: "benefits.RHB.coverForTheft" },
    { type: "", description: "benefits.RHB.oldVehicles" },
    { type: "", description: "benefits.RHB.cashback" },
    { type: "", description: "benefits.RHB.others" },
  ],
  Tune: [
    { type: "driver", description: "benefits.Tune.additionalDriver" },
    { type: "towing", description: "benefits.Tune.emergencyTowing" },
    { type: "free", description: "benefits.Tune.batteryService" },
    { type: "free", description: "benefits.Tune.tankService" },
    { type: "free", description: "benefits.Tune.tyreService" },
    { type: "free", description: "benefits.Tune.roadsideAssistance" },
    { type: "free", description: "benefits.Tune.claimAssistance" },
    { type: "free", description: "benefits.Tune.lockSmith" },
    { type: "", description: "benefits.Tune.eHailing" },
    { type: "", description: "benefits.Tune.instantApproval" },
    { type: "", description: "benefits.Tune.easyAndFastClaims" },
    { type: "", description: "benefits.Tune.coverForFEL" },
    { type: "", description: "benefits.Tune.coverForTheft" },
    { type: "", description: "benefits.Tune.oldVehicles" },
    { type: "", description: "benefits.Tune.cashback" },
    { type: "", description: "benefits.Tune.others" },
  ],
  Etiqa: [
    { type: "driver", description: "benefits.Etiqa.additionalDriver" },
    { type: "towing", description: "benefits.Etiqa.emergencyTowing" },
    { type: "free", description: "benefits.Etiqa.batteryService" },
    { type: "free", description: "benefits.Etiqa.tankService" },
    { type: "free", description: "benefits.Etiqa.tyreService" },
    { type: "free", description: "benefits.Etiqa.roadsideAssistance" },
    { type: "free", description: "benefits.Etiqa.claimAssistance" },
    { type: "free", description: "benefits.Etiqa.lockSmith" },
    { type: "", description: "benefits.Etiqa.eHailing" },
    { type: "", description: "benefits.Etiqa.instantApproval" },
    { type: "", description: "benefits.Etiqa.easyAndFastClaims" },
    { type: "", description: "benefits.Etiqa.coverForFEL" },
    { type: "", description: "benefits.Etiqa.coverForTheft" },
    { type: "", description: "benefits.Etiqa.oldVehicles" },
    { type: "", description: "benefits.Etiqa.cashback" },
    { type: "", description: "benefits.Etiqa.others" },
  ],
  Pacific: [
    { type: "driver", description: "benefits.Pacific.additionalDriver" },
    { type: "towing", description: "benefits.Pacific.emergencyTowing" },
    { type: "free", description: "benefits.Pacific.batteryService" },
    { type: "free", description: "benefits.Pacific.tankService" },
    { type: "free", description: "benefits.Pacific.tyreService" },
    { type: "free", description: "benefits.Pacific.roadsideAssistance" },
    { type: "free", description: "benefits.Pacific.claimAssistance" },
    { type: "free", description: "benefits.Pacific.lockSmith" },
    { type: "", description: "benefits.Pacific.eHailing" },
    { type: "", description: "benefits.Pacific.instantApproval" },
    { type: "", description: "benefits.Pacific.easyAndFastClaims" },
    { type: "", description: "benefits.Pacific.coverForFEL" },
    { type: "", description: "benefits.Pacific.coverForTheft" },
    { type: "", description: "benefits.Pacific.oldVehicles" },
    { type: "", description: "benefits.Pacific.cashback" },
    { type: "", description: "benefits.Pacific.others" },
  ],
  Ikhlas: [
    { type: "driver", description: "benefits.Ikhlas.additionalDriver" },
    { type: "towing", description: "benefits.Ikhlas.emergencyTowing" },
    { type: "free", description: "benefits.Ikhlas.batteryService" },
    { type: "free", description: "benefits.Ikhlas.tankService" },
    { type: "free", description: "benefits.Ikhlas.tyreService" },
    { type: "free", description: "benefits.Ikhlas.roadsideAssistance" },
    { type: "free", description: "benefits.Ikhlas.claimAssistance" },
    { type: "free", description: "benefits.Ikhlas.lockSmith" },
    { type: "", description: "benefits.Ikhlas.eHailing" },
    { type: "", description: "benefits.Ikhlas.instantApproval" },
    { type: "", description: "benefits.Ikhlas.easyAndFastClaims" },
    { type: "", description: "benefits.Ikhlas.coverForFEL" },
    { type: "", description: "benefits.Ikhlas.coverForTheft" },
    { type: "", description: "benefits.Ikhlas.oldVehicles" },
    { type: "", description: "benefits.Ikhlas.cashback" },
    { type: "", description: "benefits.Ikhlas.others" },
  ],
  Sompo: [
    { type: "driver", description: "benefits.Sompo.additionalDriver" },
    { type: "towing", description: "benefits.Sompo.emergencyTowing" },
    { type: "free", description: "benefits.Sompo.batteryService" },
    { type: "free", description: "benefits.Sompo.tankService" },
    { type: "free", description: "benefits.Sompo.tyreService" },
    { type: "free", description: "benefits.Sompo.roadsideAssistance" },
    { type: "free", description: "benefits.Sompo.claimAssistance" },
    { type: "free", description: "benefits.Sompo.lockSmith" },
    { type: "", description: "benefits.Sompo.eHailing" },
    { type: "", description: "benefits.Sompo.instantApproval" },
    { type: "", description: "benefits.Sompo.easyAndFastClaims" },
    { type: "", description: "benefits.Sompo.coverForFEL" },
    { type: "", description: "benefits.Sompo.coverForTheft" },
    { type: "", description: "benefits.Sompo.oldVehicles" },
    { type: "", description: "benefits.Sompo.cashback" },
    { type: "", description: "benefits.Sompo.others" },
  ],
  Pando: [
    { type: "driver", description: "benefits.Pando.additionalDriver" },
    { type: "towing", description: "benefits.Pando.emergencyTowing" },
    { type: "free", description: "benefits.Pando.batteryService" },
    { type: "free", description: "benefits.Pando.tankService" },
    { type: "free", description: "benefits.Pando.tyreService" },
    { type: "free", description: "benefits.Pando.roadsideAssistance" },
    { type: "free", description: "benefits.Pando.claimAssistance" },
    { type: "free", description: "benefits.Pando.lockSmith" },
    { type: "", description: "benefits.Pando.eHailing" },
    { type: "", description: "benefits.Pando.instantApproval" },
    { type: "", description: "benefits.Pando.easyAndFastClaims" },
    { type: "", description: "benefits.Pando.coverForFEL" },
    { type: "", description: "benefits.Pando.coverForTheft" },
    { type: "", description: "benefits.Pando.oldVehicles" },
    { type: "", description: "benefits.Pando.cashback" },
    { type: "", description: "benefits.Pando.others" },
  ],
  Takaful_Malaysia: [
    {
      type: "driver",
      description: "benefits.TakafulMalaysia.additionalDriver",
    },
    { type: "towing", description: "benefits.TakafulMalaysia.emergencyTowing" },
    { type: "free", description: "benefits.TakafulMalaysia.batteryService" },
    { type: "free", description: "benefits.TakafulMalaysia.tankService" },
    { type: "free", description: "benefits.TakafulMalaysia.tyreService" },
    {
      type: "free",
      description: "benefits.TakafulMalaysia.roadsideAssistance",
    },
    { type: "free", description: "benefits.TakafulMalaysia.claimAssistance" },
    { type: "free", description: "benefits.TakafulMalaysia.lockSmith" },
    { type: "", description: "benefits.TakafulMalaysia.eHailing" },
    { type: "", description: "benefits.TakafulMalaysia.instantApproval" },
    { type: "", description: "benefits.TakafulMalaysia.easyAndFastClaims" },
    { type: "", description: "benefits.TakafulMalaysia.coverForFEL" },
    { type: "", description: "benefits.TakafulMalaysia.coverForTheft" },
    { type: "", description: "benefits.TakafulMalaysia.oldVehicles" },
    { type: "", description: "benefits.TakafulMalaysia.cashback" },
    { type: "", description: "benefits.TakafulMalaysia.others" },
  ],
  Liberty: [
    { type: "driver", description: "benefits.Liberty.additionalDriver" },
    { type: "towing", description: "benefits.Liberty.emergencyTowing" },
    { type: "free", description: "benefits.Liberty.batteryService" },
    { type: "free", description: "benefits.Liberty.tankService" },
    { type: "free", description: "benefits.Liberty.tyreService" },
    { type: "free", description: "benefits.Liberty.roadsideAssistance" },
    { type: "free", description: "benefits.Liberty.claimAssistance" },
    { type: "free", description: "benefits.Liberty.lockSmith" },
    { type: "", description: "benefits.Liberty.eHailing" },
    { type: "", description: "benefits.Liberty.instantApproval" },
    { type: "", description: "benefits.Liberty.easyAndFastClaims" },
    { type: "", description: "benefits.Liberty.coverForFEL" },
    { type: "", description: "benefits.Liberty.coverForTheft" },
    { type: "", description: "benefits.Liberty.oldVehicles" },
    { type: "", description: "benefits.Liberty.cashback" },
    { type: "", description: "benefits.Liberty.others" },
  ],
  Chubb: [
    { type: "check", description: "benefits.Chubb.coverForLoss" },
    { type: "check", description: "benefits.Chubb.emergencyTowing" },
    { type: "check", description: "benefits.Chubb.replacementCarService" },
    { type: "check", description: "benefits.Chubb.hotelReimbursement" },
    { type: "check", description: "benefits.Chubb.cashRelief" },
    { type: "", description: "benefits.Chubb.liabilityToThirdParty" },
    { type: "", description: "benefits.Chubb.minorRepairService" },
    { type: "", description: "benefits.Chubb.additionalDriver" },
    { type: "sectionTitle", description: "benefits.Chubb.convenience" },
    { type: "check", description: "benefits.Chubb.digitalClaimPortal" },
    { type: "", description: "benefits.Chubb.nationwidePanelWorkshop" },
  ],
  TMIM: [
    { type: "driver", description: "benefits.TMIM.additionalDriver" },
    { type: "towing", description: "benefits.TMIM.emergencyTowing" },
    { type: "free", description: "benefits.TMIM.batteryService" },
    { type: "free", description: "benefits.TMIM.tyreService" },
    { type: "free", description: "benefits.TMIM.roadsideAssistance" },
    { type: "", description: "benefits.TMIM.instantApproval" },
  ],
};